/* You can add global styles to this file, and also import other style files */
@import '~material-design-icons/iconfont/material-icons.css';
@import './app/shared/main-theme.scss';



html, body { height: 100%; }
body { 
    margin: 0; 
    font-family: Arial, Helvetica, sans-serif; 
    background-color: mat-color($primary);
}

